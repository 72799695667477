import React, { Component } from "react";
import Header from "../components/Header";
import ChatBot from "@optimlx/villachat";
import optLogo  from '../assets/img/optlogo.png'
var __html = require('./index.html.js');
var template = { __html: __html };



class GovernmentBillAgent extends Component {

 

  render() {
    return (
      <><Header /><><div align="center">
        
        <a href="https://optimlx.com" target="_blank">
        </a>
     </div><h1 align="left"><font color="#00008B">~~~ Government Shutdown Bill Agent ~~~</font></h1><div className="card">
          <p align="left">
            Please try our AI enabled chat to learn more about the Government Shutdown Bill 
          </p>
        </div><ChatBot message={{client_id:"100005"}}/></></>
    
    );
  }
}
export default GovernmentBillAgent;